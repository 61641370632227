<template>
  <transition-group name="fade">
    <div id="AdministradorTemas" class="pl-1" v-if="show" key="administradortelatemas">
      <div class="here bg-fff py-5">
        <div class="container">
          <div class="row">
            <div class="lupa col-md-12 mx-auto text-center d-flex my-4 px-0">
              <div class="position-relative">
                <img
                  class="position-absolute"
                  src="@/assets/icones/lupa.png"
                  alt="lupa.png"
                />
                <input type="text" placeholder="Digite o nome ou o código para pesquisar " />
              </div>
              <!-- <button
                class="text-uppercase btn-style-default-less bg-222541 ml-3 color-fff shadow-sm"
              >
                aplicar
              </button> -->
            </div>
            <div class="col-md-12 text-left mb-3 px-0">
              <h2 class="title-dados pb-2">Unidades/Instituições Cadastradas</h2>
            </div>
            <div class="col-md-12 mx-auto">
              <AdministradorProfessorTemas :dataExercicios="exercicios" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition-group>
</template>

<script>
import AdministradorProfessorTemas from "@/components/administrador/AdministradorProfessorTemas";

export default {
  data() {
    return {
      show: false,
      exercicios: [
        {
          codigo: "001",
          tema: "Aprendendo o Básico 01",
          dtPublicacao: "27/09/2020",
          exercicio: [
            {
              codigo: "001",
              label: "Aprendendo o Básico - Exercício",
              exercicioDtPublicacao: "28/09/2020",
            },
            {
              codigo: "002",
              label: "Aprendendo o Básico - Exercício",
              exercicioDtPublicacao: "28/09/2020",
            },
            {
              codigo: "003",
              label: "Aprendendo o Básico - Exercício",
              exercicioDtPublicacao: "28/09/2020",
            },
          ],
        },
        {
          codigo: "002",
          tema: "Aprendendo o Básico 02",
          dtPublicacao: "29/09/2020",
          exercicio: [
            {
              codigo: "001",
              label: "Aprendendo o Básico - Exercício",
              exercicioDtPublicacao: "30/09/2020",
            },
            {
              codigo: "002",
              label: "Aprendendo o Básico - Exercício",
              exercicioDtPublicacao: "30/09/2020",
            },
          ],
        },
        {
          codigo: "002",
          tema: "Aprendendo o Básico 02",
          dtPublicacao: "29/09/2020",
          exercicio: [
            {
              codigo: "001",
              label: "Aprendendo o Básico - Exercício",
              exercicioDtPublicacao: "30/09/2020",
            },
            {
              codigo: "002",
              label: "Aprendendo o Básico - Exercício",
              exercicioDtPublicacao: "30/09/2020",
            },
          ],
        },
        {
          codigo: "003",
          tema: "Aprendendo o Básico 02",
          dtPublicacao: "29/09/2020",
          exercicio: [
            {
              codigo: "001",
              label: "Aprendendo o Básico - Exercício",
              exercicioDtPublicacao: "30/09/2020",
            },
            {
              codigo: "002",
              label: "Aprendendo o Básico - Exercício",
              exercicioDtPublicacao: "30/09/2020",
            },
          ],
        },
        {
          codigo: "004",
          tema: "Aprendendo o Básico 02",
          dtPublicacao: "29/09/2020",
          exercicio: [
            {
              codigo: "001",
              label: "Aprendendo o Básico - Exercício",
              exercicioDtPublicacao: "30/09/2020",
            },
            {
              codigo: "002",
              label: "Aprendendo o Básico - Exercício",
              exercicioDtPublicacao: "30/09/2020",
            },
          ],
        },
        {
          codigo: "005",
          tema: "Aprendendo o Básico 02",
          dtPublicacao: "29/09/2020",
          exercicio: [
            {
              codigo: "001",
              label: "Aprendendo o Básico - Exercício",
              exercicioDtPublicacao: "30/09/2020",
            },
            {
              codigo: "002",
              label: "Aprendendo o Básico - Exercício",
              exercicioDtPublicacao: "30/09/2020",
            },
          ],
        },
        {
          codigo: "006",
          tema: "Aprendendo o Básico 02",
          dtPublicacao: "29/09/2020",
          exercicio: [
            {
              codigo: "001",
              label: "Aprendendo o Básico - Exercício",
              exercicioDtPublicacao: "30/09/2020",
            },
            {
              codigo: "002",
              label: "Aprendendo o Básico - Exercício",
              exercicioDtPublicacao: "30/09/2020",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.show = true;
  },
  components: {
    AdministradorProfessorTemas,
  },
  methods: {},
};
</script>

<style scoped>
input {
  height: 40px;
  border: 1px solid #e4e5e8;
}
</style>
